<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="스케줄 상세">
            <div class="btn-ibbox title-btnbox float-right">
                <router-link :to="{ name: 'customerManagement-businessCardAdd', params:{ idx_company:view.info.idx_company } }" target="_blank"><button class="btn-default">명함 등록</button></router-link>
                <router-link v-if="!view.info.idx_schedule_outsidework && view.info.is_auth=='Y' " :to="{ name: 'customerManagement-outsideWorkAdd-idx', params:{ idx:view.idx } }"><button class="btn-default ml-10">고객사일지 작성</button></router-link>
                <router-link v-if="view.info.idx_schedule_outsidework>0" :to="{ name: 'customerManagement-outsideWorkView-idx', params:{ idx:view.info.idx_schedule_outsidework } }" ><button class="btn-default ml-10">고객사일지 보기</button></router-link>
            </div>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>일시</th>
                                <td> {{ view.info.vdate }} {{ view.info.vtime12 }}</td>
                            </tr>
                            <tr>
                                <th>목적</th>
                                <td>{{ view.info.rtype }}</td>
                            </tr>
                            <tr>
                                <th>고객사</th>
                                <td>
                                    <span> {{ view.info.company_kname }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>등록자</th>
                                <td>
                                    <span> {{ view.info.hq_ename }}({{ view.info.hq_kname }})</span>
                                </td>
                            </tr>
                            <tr>
                                <th>상세내용</th>
                                <td class="preline"> {{ view.info.contents }}</td>
                            </tr>
                            <tr>
                                <th>기타사항<br>(장소 등)</th>
                                <td class="preline"> {{ view.info.etc_memo }}</td>
                            </tr>
                            <tr>
                                <th>Self Driven</th>
                                <td class="preline"> {{ view.info.driven }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="view.goList('week')" class="btn-default float-left mt-30 mr-10">주별목록</button>
                    <button @click="view.goList('month')" class="btn-default float-left mt-30">월별목록</button>
                    <router-link v-if="view.info.is_owner=='Y'" :to="{ name:'customerManagement-scheduleVisitMod-idx', params:{ idx:view.info.idx } }"><button class="btn-default float-right mt-30">수정</button></router-link>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'


export default {
    layout:"customerManagement",
    components: {
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const view = reactive({
            idx : 0,
            info : {},

            goList : (type) => {
              if(type=='week'){
                router.push({
                  name : 'customerManagement-scheduleVisitWeekly'
                });
              }else if(type=='month'){
                router.push({
                  name : 'customerManagement-scheduleVisitMonthly'
                });
              }

            },
            doSearch : () => {
                let params = {
                    idx : view.idx
                };

                axios.get("/rest/customermgr/getScheduleInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            //console.log(store.state.idx_staff);
            view.idx = route.params.idx?route.params.idx:0;

            if ( !view.idx ) {
                router.back(-1);
            }

            view.doSearch();
        });

        return {view};
    }
}
</script>

<style lang="scss" scoped>
</style>